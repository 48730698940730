<template>
  <div class="app-container">
    <TitleShow text="聯絡我們" />
    <el-row justify="space-around">
      <el-col :span="12">
        <SubTitleShow text="聯絡窗口" />
        <div class="text-sm">
          小正<br />
          <a class="text-black" href="mailto:stf74116@gmail.com">
            stf74116@gmail.com
          </a>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="contact-form">
          <SubTitleShow text="透過表單聯絡" />
          <div class="text-sm margin-tb-xs text-bold">稱呼</div>
          <el-input class="ease-in-out-03s" v-model="data.name" />
          <div class="text-sm margin-tb-xs text-bold">電子郵件</div>
          <el-input class="ease-in-out-03s" type="email" v-model="data.email" />
          <div class="text-sm margin-tb-xs text-bold">內容</div>
          <el-input
            class="ease-in-out-03s"
            type="textarea"
            v-model="data.content"
            :autosize="{ minRows: 3, maxRows: 6 }"
          />
          <div class="text-sm margin-tb-xs text-bold">
            附件（選填、限 3 張圖片、格式為 JPG, PNG, JPEG、大小 6 MB 以內）
          </div>
          <el-upload
            ref="upload"
            name="fileUpload"
            :action="apiHost"
            list-type="picture-card"
            :on-success="handleUploadSuccess"
            :on-error="handleUploadError"
            :on-preview="handleUploadPreview"
            :on-remove="handleUploadRemove"
            :on-exceed="handleUploadExceed"
            :before-upload="handleBeforeUpload"
            multiple
            :limit="uploadLimit"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
          <el-button
            slot="reference"
            :loading="isLoading"
            class="ease-in-out-03s button margin-top pointer"
            @click="handleVerity"
          >
            送出
          </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";
import configCommon from "@/utils/configCommon";
import { getIPAddress } from "@/api/ip";
import { slackReporter } from "@/api/slack";
import DeviceDetector from "device-detector-js";
const deviceDetector = new DeviceDetector();
const device = deviceDetector.parse(navigator.userAgent);

export default {
  mixins: [mainCommon],
  data() {
    return {
      apiHost: `${configCommon.apiHost}/api/image-upload`,
      isLoading: false,
      data: {
        name: null,
        email: null,
        content: null,
        attachments: [],
      },
      uploadLimit: 3,
      dialogImageUrl: null,
      dialogVisible: false,
    };
  },
  methods: {
    handleUploadExceed(files, fileList) {
      this.$message.error(`附件數量限制 ${this.uploadLimit} 個`);
    },
    handleBeforeUpload(file) {
      this.$message("上傳中，請稍候");
    },
    handleUploadError(err, file, fileList) {
      let res = JSON.parse(err.message);
      this.$message.error(res.message);
    },
    handleUploadSuccess(res, file, fileList) {
      this.$message.success(`附件上傳成功`);
      this.data.attachments.push(res.data);
    },
    handleUploadRemove(file, fileList) {
      this.$message.success(`附件移除成功`);
    },
    handleUploadPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleVerity() {
      if (!this.data.name) return this.$message.error("請輸入稱呼");
      if (!this.data.email) return this.$message.error("請輸入電子郵件");
      if (!this.data.content) return this.$message.error("請輸入內容");

      this.$confirm("確定要送出嗎？", "警告", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleSubmit();
        })
        .catch(() => {});
    },
    handleSubmit() {
      this.isLoading = true;
      this.$message("處理中，請稍候");
      getIPAddress().then((res) => {
        let fields = [
          {
            title: "Browser",
            value: `${device.client.name} (Version: ${device.client.version})`,
            short: true,
          },
          {
            title: "Device",
            value: `${device.device.brand || "Unknown"} (Type: ${
              device.device.type || "Unknown"
            })`,
            short: true,
          },
          {
            title: "OS",
            value: `${device.os.name} (Version: ${
              device.os.version || "Unknown"
            })`,
            short: true,
          },
          {
            title: "IP",
            value: res.ip,
            short: true,
          },
          {
            title: "Host",
            value: location.host,
            short: true,
          },
          {
            title: "Name",
            value: this.data.name,
            short: true,
          },
          {
            title: "Email",
            value: this.data.email,
            short: true,
          },
          {
            title: "Content",
            value: this.data.content,
            short: false,
          },
        ];
        if (this.data.attachments.length !== 0)
          this.data.attachments.forEach((res, i) => {
            fields.push({
              title: `Attachment ${i + 1}`,
              value: res,
              short: false,
            });
          });
        slackReporter({
          text: `${res.ip} just contacted us!`,
          channel: "contact",
          attachments: [
            {
              color: "#2ec9d3", // 2ec9d3 | f77d7d | 36a64f
              fields: fields,
            },
          ],
        }).then((res) => {
          this.$message.success("送出成功");
          this.isLoading = false;
          this.data.name = null;
          this.data.email = null;
          this.data.content = null;
          this.data.attachments = [];
          this.$refs.upload.clearFiles();
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-upload-list__item {
  width: 120px;
  height: 120px;
}
/deep/ .el-upload-list__item-thumbnail {
  object-fit: cover;
}
/deep/ .el-upload--picture-card {
  width: 120px;
  height: 120px;
  line-height: 120px;
}
.button {
  border: 1px #fff solid;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  background: #fbfbfb;
  color: #000;
  font-size: 16px;
  &:hover {
    opacity: 0.5;
  }
}
/deep/ .el-input__inner,
/deep/ .el-textarea__inner {
  background-color: #fbfbfb;
  color: #333;
  padding: 15px;
  width: 100%;
  border: 0;
  border-radius: 0;
  border-bottom: 1px #ccc solid;
  font-size: 16px;
  &:hover {
    border-bottom: 1px #fff solid;
  }
}
.contact-form {
  // margin: 50px 0;
  width: 90%;
}

@media screen and (max-width: 659px) {
  .contact-form {
    margin: 0;
    width: 100%;
  }
  /deep/ .el-input__inner,
  /deep/ .el-textarea__inner {
    font-size: 19px;
  }
  .el-col {
    width: 100%;
    margin-bottom: 30px;
  }
}
</style>